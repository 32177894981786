import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import { UserModule } from '@/store/modules/user'

const http = axios.create({
  baseURL: '/api',
  timeout: 5000,
})

http.interceptors.request.use(
  (config) => {
    if (UserModule.token) {
      config.headers['X-Token'] = UserModule.token
    }
    return config
  },
  (e) => {
    Promise.reject(e)
  }
)

http.interceptors.response.use(
  (rsp) => {
    return rsp
  },
  (e) => {
    if (e.response && e.response.status == 401) {
      MessageBox.confirm('未登录或登录超时，请重新登录', '登录', {
        confirmButtonText: '登录',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        UserModule.ResetToken()
        location.reload()
      })
    } else {
      // Message({
      //   message: (e.response && e.response.data && e.response.data.message) || e.message,
      //   type: 'error',
      //   duration: 5 * 1000,
      // })
    }
    return Promise.reject(e)
  }
)

export default http
