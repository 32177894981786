import http from '@/utils/http'

export const login = (data: any) => http.post('/login', data)
// new Promise<any>((resolve, reject) => {
//   if (data.idCard == '8888') {
//     resolve({ data: { token: 'test-token' } })
//   } else {
//     reject({ data: { message: '未授权考试' } })
//   }
// })

export const getUserInfo = () => http.get('/info')

export const startExam = () => http.get('/start')
export const getQuestions = () => http.get('/question')
export const updateSheet = (data: any) => http.post('/update', data)
export const commitSheet = (data: any) => http.post('/commit', data)
export const syncLeftTime = () => http.get('/left/sync')
